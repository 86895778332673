body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link { 
  text-decoration: none; 
} 
a:visited { 
  text-decoration: none; 
} 
a:hover { 
  text-decoration: none; 
} 
a:active { 
  text-decoration: none; 
}

th{
  text-align: right;
}

th, td {
  border-bottom: 0.5px #EEE solid;
  padding: 5px 30px 5px 30px;
}

tr:last-child>th, tr:last-child>td{
  border-bottom: 0 #fff solid;
}

.tool-email {
  line-height: 250%;
}

.home {
  width: 100%;
  margin: auto;
  background-color: #111;
  text-align: center;
}

.row {
  padding: 20px 10% 0 10%;
  height: 580px;
}

.title {
  background-image: url('https://static.wixstatic.com/media/a1b568_7f107008dad744d98baf81b2d5e83da3~mv2.png/v1/fill/w_1663,h_892,al_c,q_90,enc_avif,quality_auto/a1b568_7f107008dad744d98baf81b2d5e83da3~mv2.png');
  background-size: 100%, 100%;
  background-position: center, center;
}

.text {
  display: inline-block;
  max-width: 680px;
  font-weight: bold;
  font-size: x-large;
  color: #fff;
  text-align: left;
  padding-left: 20px;
}


.text>div:first-child {
  color: #ffffff88;
  font-size: 200px;
  offset: 0 -100px;
  transform: translateY(50%);
  padding-left: 0;
}

.text>div:last-child {
  color: #ffffff88;
  text-align: right;
  font-size: 200px;
  transform: translateY(-15%);
  padding-left: 0;
}

.logo {
  color: #fff;
  font-weight: bold;
  font-size: x-large;
  text-align: left;
  padding: 20px 10% 0 10%;
}

.download {
  color: #000;
  margin-top: 5px;
  padding: 10px 30px 10px 30px;
  border-radius: 8px;
  border: 0;
  background-color: #bbfa0b;
  font-weight: bold;
  font-size: small;
  float: right;
}

.download:hover {
  color: #fff;
  background-color: #9c42fd;
}

.home * {
  font-family: "Lexend";
}

.info {
  background-color: #9c42fd;
  overflow: hidden;
}

.store {
  background-color: #bbfa0b;
  padding-top: 125px;
  text-align: center;
}

.code {
  display: inline-block;
  width: 400px;
  padding-top: 30px;
}

.explain {
  display: inline-block;
  width: 500px;
  text-align: left;
}

.connect {
  height: 80px;
  text-align: left;
  padding: 20px 10% 0 10%;
}

.store>div {
  display: inline-block;
  width: 980px;
  height: 350px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 20px rgb(150 203 1);
  transform: translateY(7%);
}

.info>div{
  transform: translateY(-5%);
}

.info>img {
  transform: translateY(-42%);
}

.code>img:first-child {
  width: 233px;
  height: 233px;
  transform: translate(20%, 10%);
}

.code>img:last-child {
  width: 157px;
  height: 153px;
}

.explain>img {
  width: 200px;
  height: 65px;
}

.explain>div:first-child {
  font-size: xx-large;
  font-weight: bold;
}

.e-info {
  font-size: x-large;
  font-weight:200;
  padding: 30px 0 30px 0;
}

.connect>div:first-child {
  display: inline-block;
  width: 400px;
}

.connect a {
  color: #bbfa0b;
}

.connect>div:last-child {
  display: inline-block;
  width: 500px;
  text-align: right;
  padding-top: 10px;
  float: right;
}

.connect>div>img {
  width: 71px;
  height: 38px;
  transform: translateY(20%);
}

.connect>div>span {
  color: #fff;
}

.connect>div>a>img {
  width: 24px;
  height: 24px;
  transform: translateY(20%);
}

.hide {
  display: none;
}

.show {
  display: block;
}